import { getProductImageUrlsByOrientation, ProductImageOrientation, includesBumpImages } from "./product-image-helpers";
import { AccessoryCategoryIds, AccessoryRootCategoryIdDisco } from "rtr-constants";
import { isStorefrontNext } from "helpers/environment-helpers";

// [09/2024] Disco is following a different path structure for bump and plus images
// hence have changed some of the rotations to check for both path (follows ruby nomenclature) and legacy path
// (follows disco nomenclature)

const PLUS_SIZE_CUTOFF = 12;

// Disco powered categories
const JEWELRY_ROTATION_ACCESSORY_CATEGORIES = Object.freeze(["Earrings", "Necklaces", "Sunglasses"]);

// Godfather (taxonomy) powered categories
const JEWELRY_ROTATION_LEGACY_ACCESSORY_CATEGORIES = Object.freeze([
  "accessory",
  "bangle",
  "belt",
  "bib",
  "bracelet",
  "bridal_accessory",
  "clip_on",
  "collar",
  "comb",
  "cuff",
  "earring",
  "hair_piece",
  "hand_chain",
  "handbag",
  "hat",
  "jewelry",
  "lariat",
  "necklace",
  "pendant",
  "pierced",
  "ring",
  "soft_bracelet",
  "sunglasses",
  "veil",
  "watch",
]);

export const MISSY_ONLY_ROTATION = Object.freeze([
  ProductImageOrientation.FRONT.path,
  ProductImageOrientation.BACK.path,
  ProductImageOrientation.SIDE.path,
  ProductImageOrientation.TOP.path,
  ProductImageOrientation.WITH_MODEL.path,
  ProductImageOrientation.EDITORIAL.path,
  ProductImageOrientation.NO_MODEL.path,
  ProductImageOrientation.NO_MODEL.legacyPath,
  ProductImageOrientation.FRONT_PLUS.path,
  ProductImageOrientation.FRONT_PLUS.legacyPath,
  ProductImageOrientation.BACK_PLUS.path,
  ProductImageOrientation.BACK_PLUS.legacyPath,
  ProductImageOrientation.SIDE_PLUS.path,
  ProductImageOrientation.SIDE_PLUS.legacyPath,
]);

export const PLUS_ONLY_ROTATION = Object.freeze([
  ProductImageOrientation.FRONT_PLUS.path,
  ProductImageOrientation.FRONT_PLUS.legacyPath,
  ProductImageOrientation.BACK_PLUS.path,
  ProductImageOrientation.BACK_PLUS.legacyPath,
  ProductImageOrientation.SIDE_PLUS.path,
  ProductImageOrientation.SIDE_PLUS.legacyPath,
  ProductImageOrientation.TOP.path,
  ProductImageOrientation.WITH_MODEL.path,
  ProductImageOrientation.EDITORIAL.path,
  ProductImageOrientation.NO_MODEL.path,
  ProductImageOrientation.FRONT.path,
  ProductImageOrientation.BACK.path,
  ProductImageOrientation.SIDE.path,
]);

export const MIXED_ROTATION = Object.freeze([
  ProductImageOrientation.FRONT.path,
  ProductImageOrientation.BACK.path,
  ProductImageOrientation.FRONT_PLUS.path,
  ProductImageOrientation.FRONT_PLUS.legacyPath,
  ProductImageOrientation.BACK_PLUS.path,
  ProductImageOrientation.BACK_PLUS.legacyPath,
  ProductImageOrientation.TOP.path,
  ProductImageOrientation.WITH_MODEL.path,
  ProductImageOrientation.EDITORIAL.path,
  ProductImageOrientation.NO_MODEL.path,
]);

export const MATERNITY_ROTATION = Object.freeze([
  ProductImageOrientation.BUMP_FRIENDLY.path,
  // adding legacy path as Disco is sending as "bump_friendly" format while ruby uses "bumpFriendly"
  ProductImageOrientation.BUMP_FRIENDLY.legacyPath,
  ProductImageOrientation.BUMP_FRONT.path,
  ProductImageOrientation.BUMP_FRONT.legacyPath,
  ProductImageOrientation.BUMP_SIDE.path,
  ProductImageOrientation.BUMP_SIDE.legacyPath,
  ProductImageOrientation.BACK.path,
  ProductImageOrientation.SIDE.path,
  ProductImageOrientation.NO_MODEL.path,
]);

export const JEWELRY_ROTATION = Object.freeze([
  ProductImageOrientation.TOP.path,
  ProductImageOrientation.FRONT.path,
  ProductImageOrientation.NO_MODEL.path,
]);

// Currently we return all the images from Dorothy to our Ruby layer, but we do not display them all.
// This function determines the product images we want to display and the order of those images.
// Most of these image tags are self-explanatory, editorial is an outfits shot. Going forward we will have an outfit tag.

// Maternity Rotation is used for users who have told us that they are expecting in the future and we have a bumpFriendly image and user has not selected plus sizes
function productImageRotation(sizes, product, showMaternityBumpImage, noModelImageFirst = false) {
  const sizesAsInts = sizes.map(s => parseInt(s, 10));
  const inPlusExperience = sizesAsInts.length && Math.min(...sizesAsInts) > PLUS_SIZE_CUTOFF;
  const hasBumpImages = product?.images ? includesBumpImages(product.images) : false;
  const requiredBumpFriendlyImages = getProductImageUrlsByOrientation(
    product?.images,
    [
      ProductImageOrientation.BUMP_FRIENDLY.path,
      ProductImageOrientation.BUMP_FRIENDLY.legacyPath,
      ProductImageOrientation.BUMP_FRONT.path,
      ProductImageOrientation.BUMP_SIDE.path,
    ],
    false
  );

  const buildImageOrderNext = () => {
    const isAccessory =
      product.categoryRoot === AccessoryRootCategoryIdDisco ||
      AccessoryCategoryIds.includes(product.category?.id) ||
      false;

    const hasPlusExperienceImages = getProductImageUrlsByOrientation(
      product?.images,
      [ProductImageOrientation.FRONT_PLUS.path, ProductImageOrientation.FRONT_PLUS.legacyPath],
      false
    );

    if (
      JEWELRY_ROTATION_ACCESSORY_CATEGORIES.includes(product.category) ||
      JEWELRY_ROTATION_LEGACY_ACCESSORY_CATEGORIES.includes(product.category?.id)
    ) {
      return JEWELRY_ROTATION;
    }
    if (isAccessory) {
      return MIXED_ROTATION;
    } else if (showMaternityBumpImage && requiredBumpFriendlyImages && hasBumpImages) {
      // if told maternity and has maternity images then show maternity images
      return MATERNITY_ROTATION;
    } else if (inPlusExperience && hasPlusExperienceImages) {
      return PLUS_ONLY_ROTATION;
    } else {
      return MISSY_ONLY_ROTATION;
    }
  };

  const buildImageOrderLegacy = () => {
    const isAccessory = AccessoryCategoryIds.includes(product.category?.id) || false;
    const inMissyExperience = sizesAsInts.length && Math.max(...sizesAsInts) <= PLUS_SIZE_CUTOFF;

    if (JEWELRY_ROTATION_LEGACY_ACCESSORY_CATEGORIES.includes(product.category?.id)) {
      return JEWELRY_ROTATION;
    } else if (
      showMaternityBumpImage &&
      requiredBumpFriendlyImages &&
      inPlusExperience &&
      getProductImageUrlsByOrientation(product?.images, [ProductImageOrientation.FRONT_PLUS.path], false)
    ) {
      return PLUS_ONLY_ROTATION;
    } else if (showMaternityBumpImage && requiredBumpFriendlyImages && !isAccessory && hasBumpImages) {
      // if there are no maternity images we return missyOnlyRotation
      return MATERNITY_ROTATION;
    } else if (!getProductImageUrlsByOrientation(product?.images, [ProductImageOrientation.FRONT_PLUS.path], false)) {
      return MISSY_ONLY_ROTATION;
    } else if (!sizesAsInts.length) {
      return MIXED_ROTATION;
    } else if (inPlusExperience) {
      return PLUS_ONLY_ROTATION;
    } else if (inMissyExperience) {
      return MISSY_ONLY_ROTATION;
    } else {
      return MIXED_ROTATION;
    }
  };

  const imageOrder = isStorefrontNext() ? buildImageOrderNext() : buildImageOrderLegacy();

  if (noModelImageFirst) {
    //if true move "noModel" image order to front of the image rotation
    return [imageOrder[imageOrder.length - 1], ...imageOrder.slice(0, imageOrder.length - 1)];
  }

  return imageOrder;
}

export default productImageRotation;
